import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { datoApi, getApiSettingsFromHost } from '@hultafors/wibe/api';
import { spacing } from '@hultafors/wibe/helpers';
import {
  GlobalFields,
  PageNotFoundFragment,
  PageProps,
} from '@hultafors/wibe/types';

const Grid = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Grid),
);
const GridChild = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.GridChild),
);
const HTag = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.HTag),
);
const NotFoundContainer = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.NotFoundContainer,
  ),
);
const Paragraph = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Paragraph),
);
const Section = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Section),
);
const TextButton = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.TextButton),
);

export interface PageNotFoundProps extends PageProps {
  content: PageNotFoundFragment;
}

const Custom404: NextPage<PageNotFoundProps> = ({
  // content: defaultContent,
  // settings: defaultSettings,
  content,
}) => {
  // const [, setSettings] = useState<ApiSettings>(defaultSettings);
  // const [content, setContent] = useState<PageNotFoundFragment>(defaultContent);
  // const {
  //   setGlobal,
  //   setAllMenuItems,
  //   setFooter,
  //   setLanguageSelector,
  //   setMaintenanceMode,
  //   setMenu,
  //   setTimedDialog,
  // } = useGlobal();
  // const { data: pageData, error: pageError } = useSWR<PageNotFoundResponse>(
  //   '/api/pageNotFound',
  //   {
  //     revalidateOnMount: true,
  //   },
  // );

  // useEffect(() => {
  //   setSettings(getApiSettingsFromHost(window.location.hostname));
  // }, []);

  // useEffect(() => {
  //   if (pageData?.content) {
  //     setContent(pageData.content);
  //   }
  //   if (pageData?.global) {
  //     setGlobal(pageData.global);
  //   }
  //   if (pageData?.footer) {
  //     setFooter(pageData.footer);
  //   }
  //   if (pageData?.menu) {
  //     setMenu(pageData.menu);
  //   }
  //   if (pageData?.allMenuItems) {
  //     setAllMenuItems(pageData.allMenuItems);
  //   }
  //   if (pageData?.languageSelector) {
  //     setLanguageSelector(pageData.languageSelector);
  //   }
  //   if (pageData?.maintenanceMode) {
  //     setMaintenanceMode(pageData.maintenanceMode);
  //   }
  //   if (pageData?.timedDialog) {
  //     setTimedDialog(pageData.timedDialog);
  //   }
  // }, [pageData]);

  // useEffect(() => {
  //   if (pageError) {
  //     setContent(defaultContent);
  //   }
  // }, [pageError]);

  return (
    <NotFoundContainer>
      <Section>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 10, start: 2 },
            ]}
          >
            <HTag
              style={{ marginBottom: spacing.m }}
              color="wibeDarkGrey"
              type="h1"
              styleType={[
                { styleType: 'header2' },
                { breakpoint: 'mobileMax', styleType: 'header1' },
              ]}
            >
              {content.heroTitle}
            </HTag>
            <Paragraph
              style={{ marginBottom: spacing.m }}
              styleType="paragraph16Medium"
            >
              {content.heroDescription}
            </Paragraph>
            <TextButton arrow clean to="/">
              {content.backToPage}
            </TextButton>
          </GridChild>
        </Grid>
      </Section>
    </NotFoundContainer>
  );
};

export const getStaticProps: GetStaticProps<PageNotFoundProps> = async ({
  locale,
}) => {
  try {
    // Fetch default locale data
    const settings = getApiSettingsFromHost(
      'www.wibeladders.com',
      locale && locale !== 'com' ? locale : undefined,
    );
    const dato = datoApi(settings);

    // Fetch page content
    const {
      data: { pageNotFound: content, ...globalFields },
    } = await dato.getNotFoundPage();

    const props: PageNotFoundProps = {
      content,
      settings,
      ...(globalFields as GlobalFields),
    };
    return { props };
  } catch (error) {
    console.error(error);
    return {
      props: {} as PageNotFoundProps,
    };
  }
};

export default Custom404;
